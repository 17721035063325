// lots of this is taken from
// https://github.com/backstage/backstage/issues/22501
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  scmAuthApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiRef,
  ApiRef,
  OAuthApi,
  ProfileInfoApi,
  SessionApi,
  discoveryApiRef,
  oauthRequestApiRef,
  githubAuthApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  GithubAuth,
} from '@backstage/core-app-api';

export const gheAuthApiRef: ApiRef<OAuthApi & ProfileInfoApi & SessionApi> =
  createApiRef({
    id: 'internal.auth.ghe',
  });

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
//  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: gheAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      GithubAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: { id: 'github', title: 'UT GitHub Enterprise', icon: () => null },
        defaultScopes: ['read:user'],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      gheAuthApi: gheAuthApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi, gheAuthApi }) =>
      ScmAuth.merge(
        ScmAuth.forGithub(githubAuthApi),
        ScmAuth.forGithub(gheAuthApi, {
          host: 'github.austin.utexas.edu',
        }),
      ),
  }),
];
